<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>Honor Casino 베팅내역</sub-title>
                <bet-info-links></bet-info-links>
                <div style="margin: 5px 3px">
                    <button @click="deleteAll" class="badge badge-red" style="padding: 5px 15px;margin-left: 2px"> <i class="fa fa-close"> 전체삭제</i></button>
                </div>
                <div class="board_list">
                    <table class="utable">
                        <tr>
                            <th style="width: 10%">시간</th>
                            <th style="width: 10%">번호</th>
                            <th style="width: 10%">타입</th>
                            <th style="width: 20%">벤더사</th>
                            <th style="width: 10%">베팅금액</th>
                            <th style="width: 10%">당첨금</th>
                            <th style="width: 10%">결과</th>
                        </tr>
                        <tr  v-for="(item,idx) in resultList" :key="idx">
                            <td>{{item.createTime|datef('MM-DD HH:mm')}}</td>
                            <td>{{item.id}}</td>
                            <td>{{item.gtype}}</td>
                            <td>
                                {{item.vendor}}
                            </td>
                            <td>
                                {{item.betamount | comma}}
                            </td>
                            <td>
                                {{item.winamount | comma}}
                            </td>
                            <td>
                                <span v-if="item.betResult === 1" class="text-skyblue">당첨</span>
                                <span v-if="item.betResult === 2" class="">x</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <pagination :page-index=" pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>
            </div>
        </div>

        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
        <mobile-foot-menus></mobile-foot-menus>
    </div>

</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import SubTitle from "../../components/SubTitle";
    import leisureConst from "../../common/leisureConst";
    import sportsConst from "../../common/sportsConst";
    import Pagination from "../../components/pagenation/Pagination";
    import LeisureGameLinksComp from "../../components/leisuregame/LeisureGameLinksComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import GameResultLinks from "../../components/GameResultLinks";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import TopbarComp from "../../components/TopbarComp";
    import BetInfoLinks from "../../components/BetInfoLinks";
    import {deleteAllHonorBet, getHonorBetList} from "../../network/casinoHonorRequest";
    import MobileFootMenus from "../../components/MobileFootMenus";
    import {postionMixin} from "../../common/mixin";


    export default {
        name: "HonorCasinoBetInfo",
        mixins: [postionMixin],
        components: {
            MobileFootMenus,
            BetInfoLinks,
            TopbarComp,
            UserInfoComp,
            RightButtonsComp,
            SportsBetCartComp,
            GameResultLinks,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp,
            ScrollTopComp, LeisureGameLinksComp, Pagination, SubTitle, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                position: "C베팅내역",
                leisureConst,
                sportsConst,
                pageNum: 1,
                pageSize: 20,
                total: 1,
                resultList: []
            }
        },
        methods: {
            deleteAll(){
                this.$swal({
                    title: '베팅내역 전체를 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllHonorBet().then(res=>{
                            this.getHonorBetList()
                        })
                    }
                });

            },
            getHonorBetList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getHonorBetList(this.pageNum, this.pageSize, this.orderBy).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.resultList = res.data.data
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.getHonorBetList()
            }
        },
        created() {
            this.getHonorBetList();
        },
        watch: {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .button_group button {
        border: 2px solid #ffffff;
    }
    .result p {
        display: inline-block;
        margin-right: 5px;
    }

    @media screen and (max-width: 800px) {
        .result p {
            display: block;
            margin-right: 0;
        }
    }
</style>